import React from "react";
import classNames from "classnames";

import { CryptoDetails } from "types";
import { formatPrice } from "./table-row.utils";
import { ArrowUp, ArrowDown, Calendar } from "assets";
import { Badge } from "components/crypto-table/badge/badge";

import styles from "./table-row.module.scss";

interface Props {
  data: CryptoDetails;
  index: number;
}

export const TableRow: React.FC<Props> = ({ data, index }) => {
  const showBg = index % 2 !== 0;

  const tableCellClass = showBg ? styles.tableCellBackground : styles.tableCell;
  const percentageCellClass = showBg
    ? styles.percentageTableCellBackground
    : styles.percentageTableCell;

  const date = new Date(data.entryDate);
  const dateWithYear = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <>
      <div className={tableCellClass}>
        <div className={styles.coinDetails}>
          <img src={data.imageUrl} className={styles.logo} alt="Logo" />
          <div className={styles.name}>
            <span className={styles.coinName}>{data.name}</span>
            <span>
              <span className={styles.symbol}>{data.symbol}</span>{" "}
              <span className={styles.rank}>#{data.rank}</span>
            </span>
          </div>
        </div>
      </div>

      <div className={classNames(tableCellClass, styles.date)}>
        <Badge>
          <Calendar />
          <div>
            <span>{dateWithYear}</span>
          </div>
        </Badge>
      </div>

      <div className={tableCellClass}>
        {formatPrice(data.entryPrice.toString())}
      </div>

      <div className={tableCellClass}>
        {formatPrice(data.lastPrice.toString())}
      </div>

      <div className={tableCellClass}>{data.yield}</div>

      <div className={tableCellClass}>
        {/* AMOUNT */}
        {/*<span className={styles.profit}>*/}
        {/*  {formatPrice(randomizeProfit.toString())}*/}
        {/*</span>*/}
        <section
          className={classNames(styles.row, percentageCellClass, {
            [styles.tableCellPlus]: data.gainLoss > 0,
            [styles.tableCellMinus]: data.gainLoss < 0,
          })}
        >
          {data.gainLoss < 0 && (
            <Badge className={styles.lossBadge}>
              <ArrowDown />
              <span>{data.gainLoss.toFixed(2)}%</span>
            </Badge>
          )}
          {data.gainLoss > 0 && (
            <Badge className={styles.profitBadge}>
              <ArrowUp />
              <span>{data.gainLoss.toFixed(2)}%</span>
            </Badge>
          )}
        </section>
      </div>

      <div className={tableCellClass}>{data.buyUpTo}</div>
    </>
  );
};
